import BigNumber from 'bignumber.js'
import { BlockchainUtils, Models, TypeUtils } from 'carbon-js-sdk'
import { ExternalTokenMapping } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/bridge/bridge'
import { TokenBalance } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { createSelector } from 'reselect'

import { TransferMethod } from 'js/constants/TransferOptions'
import { Carbon, CarbonEVM, parseChainName, splitConnectionId } from 'js/utils/externalTransfer'
import { BN_ZERO } from 'js/utils/number'

import { AdjustedBalance, BlockchainToDenomMap, BridgeTransferMethods, DenomValue, NetworkWithFeesArr, SelectedToken, TokenContractButtonPopUpState, TokenProperties, TransferSource, UserPortfolio, WalletBalance, WalletFeesMap } from './types'

import { getExternalTokens } from '../bridge/selectors'
import { RootState } from '../rootReducer'

export function getWalletBalances(state: RootState): TypeUtils.SimpleMap<WalletBalance> {
  return state.walletBalance.balances
}
export function getPortfolio(state: RootState): UserPortfolio {
  return state.walletBalance.portfolio
}

export function getEvmAddressBalances(state: RootState): TypeUtils.SimpleMap<TokenBalance> {
  return state.walletBalance.evmBalances
}
export function getCarbonBalances(state: RootState): TypeUtils.SimpleMap<TokenBalance> {
  return state.walletBalance.carbonBalances
}

export function getQuoteBalances(state: RootState): WalletBalance | null {
  const { walletBalance, exchange } = state
  const quote = exchange.market?.quote ?? ''
  return walletBalance.balances?.[quote] ?? null
}

export function getSelectedToken(state: RootState): SelectedToken | null {
  return state.walletBalance.selectedToken
}

export function getSelectedDenom(state: RootState): string | undefined {
  return state.walletBalance.selectedToken?.denom
}

export function getGroupTokenOption(state: RootState): string {
  return state.walletBalance.groupTokenOption
}

export function getNetworksWithFeesArr(state: RootState): NetworkWithFeesArr {
  return state.walletBalance.chainSelectOptions.optionList
}

export function getBlockchainDenomMap(state: RootState): BlockchainToDenomMap {
  return state.walletBalance.chainSelectOptions.denomMap
}

export function getChainSelectOpen(state: RootState): boolean {
  return state.walletBalance.chainSelectOpen
}

export function getWalletFees(state: RootState): WalletFeesMap {
  return state.walletBalance.walletFees
}

export function getIsUSD(state: RootState): any {
  return state.walletBalance.isUSD
}

export function getRawDelegations(state: RootState): Models.Staking.DelegationResponse[] | null {
  return state.walletBalance.rawDelegations
}

export function getRawUnbondingDelegations(state: RootState): Models.Staking.UnbondingDelegation[] | null {
  return state.walletBalance.rawUnbondingDelegations
}

/** @deprecated use getRawDelegations */
export function getAccountDelegations(state: RootState): Models.Staking.DelegationResponse[] {
  return []
}

export function getNeoTokenBalance(state: RootState): BigNumber {
  return state.walletBalance.neoTokenBalance
}

export function getTokenContractButtonPopupState(state: RootState): TokenContractButtonPopUpState {
  return state.walletBalance.tokenContractButtonPopUpState
}

export function getAdjustedStake(state: RootState): TypeUtils.SimpleMap<BigNumber> {
  return state.walletBalance.adjustedStake
}

export function getTokenProperties(state: RootState): TypeUtils.SimpleMap<TokenProperties> {
  return state.walletBalance.tokenProperties
}

export function getAdjustedBalances(state: RootState): TypeUtils.SimpleMap<AdjustedBalance> {
  return state.walletBalance.adjustedBalances
}

export function getAdjustedBalance(denom: string = ''): (state: RootState) => AdjustedBalance | undefined {
  return (state: RootState) => state.walletBalance.adjustedBalances[denom]
}

export function getNonZeroBalanceDenoms(state: RootState): string[] {
  return state.walletBalance.nonZeroBalanceDenoms ?? []
}

export function getDerivedTokenRedemption(state: RootState): Record<string, DenomValue[]> {
  return state.walletBalance.derivedTokenRedemption
}

export function getDecimals(denom: string = ''): (state: RootState) => number {
  return (state: RootState) => {
    return state.walletBalance.tokenProperties[denom]?.tokenCDPInfo.isCdpToken
      ? state.walletBalance.tokenProperties[denom]?.tokenCDPInfo.underlyingDecimals
      : state.walletBalance.tokenProperties[denom]?.tokenBasicInfo.decimals
      ?? 0
  }
}

export function getUsdValue(denom: string) {
  return (state: RootState) => {
    // if token is derived (e.g. cdp or lp token)
    const underlyingTokens = state.walletBalance.derivedTokenRedemption[denom]
    return underlyingTokens?.reduce((sum, [underlyingDenom, rate]) => {
      // need to convert to human before applying USD conversion rate
      const denomDecimals = getDecimals(denom)(state)
      const underlyingDecimals = getDecimals(underlyingDenom)(state)
      const hRate = rate.shiftedBy(denomDecimals - underlyingDecimals)
      return sum.plus(hRate.times(state.app.carbonSDK?.token.usdValues[underlyingDenom] ?? 0))
    }, BN_ZERO)

      // otherwise, get token price as if its underlying
      ?? state.app.carbonSDK?.token.usdValues[denom]
  }
}

export function isPacketForwardToken(state: RootState): boolean {
  return !!state.walletBalance.transferOption.additionalTokenInfo?.isPacketForwardToken
}

export function getOptionDenom(state: RootState): string | undefined {
  return state.walletBalance.transferOption.optionDenom
}

export function getSelectedBlockchain(state: RootState): BlockchainUtils.BlockchainV2 | undefined {
  return state.walletBalance.transferOption.blockchain
}

export const getAvailableTransferMethods = createSelector(
  [(state: RootState) => state.walletBalance.bridgeTransferMethods,
    getSelectedBlockchain],
  (bridgeTransferMethods: BridgeTransferMethods, selectedBlockchain: BlockchainUtils.BlockchainV2 | undefined = ''): TransferMethod[] => {
    return bridgeTransferMethods[selectedBlockchain] ?? []
  }
)

export function getTransferSource(state: RootState): TransferSource | undefined {
  return state.walletBalance.transferOption.source
}

export function hasInitiatedExternalTransfer(state: RootState): boolean {
  return !!state.walletBalance.initiatedTxDetails
}

export const getTransferTargetDenom = createSelector(
  [getSelectedDenom, getOptionDenom],
  (selectedDenom: string | undefined, optionDenom: string | undefined): string | undefined => {
    return optionDenom ?? selectedDenom
  }
)

const getExternalTokenByTargetDenom = createSelector(
  [getTransferTargetDenom, getExternalTokens],
  (targetDenom: string | undefined, externalTokens: TypeUtils.SimpleMap<ExternalTokenMapping>): ExternalTokenMapping | undefined => {
    return targetDenom ? externalTokens[targetDenom] : undefined
  }
)

export const getTransferMethod = createSelector(
  [
    getSelectedBlockchain,
    getAvailableTransferMethods,
    getExternalTokenByTargetDenom,
  ],
  (
    selectedBlockchain: BlockchainUtils.BlockchainV2 | undefined,
    availableTransferMethods: TransferMethod[],
    axelarExternalTokenMapping: ExternalTokenMapping | undefined
  ): TransferMethod | undefined => {
    if (!selectedBlockchain) return
    if (selectedBlockchain === CarbonEVM) return TransferMethod.EVMBridge
    if (selectedBlockchain === Carbon) return TransferMethod.Internal
    if (availableTransferMethods.includes(TransferMethod.IBC)) return TransferMethod.IBC
    if (axelarExternalTokenMapping) {
      const { chainName } = splitConnectionId(axelarExternalTokenMapping.connectionId)
      const chain = parseChainName(chainName)
      if (chain?.toLowerCase() === selectedBlockchain?.toLowerCase()) return TransferMethod.AxelarBridge
    }
    return TransferMethod.LegacyPolynetwork
  }
)


