import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { BN_ZERO } from 'js/utils/number'

import { CompetitionActionTypes, CompetitionState, CompetitionStateProps } from './types'

let disableOpen: boolean = false
const disableLocal = localStorage.getItem(CompetitionActionTypes.SET_DISABLE_OPEN_SIGNUP)
if (disableLocal && disableLocal === 'true') {
  disableOpen = true
}

export const DefaultInitialState:
  Record.Factory<CompetitionStateProps> = Record<CompetitionStateProps>({
    cumulativeVolume: BN_ZERO,
    volumeData: [],
    pnlData: [],
    lotteryData: [],
    leagueData: [],
    cosmosDataSubCompetitionIdMap: {},
    currentCompetition: undefined,
    pastCompetition: undefined,
    upcomingCompetition: undefined,
    subCompetitionsMap: {},
    activeStep: 1,
    disableOpen,
    isRegistered: false,
    registeredSubCompetitionId: undefined,

    carbonCreditsRootHash: '',
    carbonCreditsSeasons: [],
    accumulatedSeasonsRewards: [],
    ongoingSeason: undefined,
    totalAccumulatedRewards: undefined,
    unclaimedRewards: undefined,
    merkleTree: [],
    leaderboardPnl: [],

    userDemexPoints: undefined,
    demexPointsLeaderboard: undefined,
    hideDemexPointsGuide: [],
    userTradingVolumeCampaign: BN_ZERO,

    lossProtectionLeaderboard: undefined,
    showLossProtectionGuide: false,
    showLossProtectionRegistration: false,

    memeTradingLeaderboard: undefined,
    showMemeTradingGuide: false,
    showMemeTradingRegistration: false,
  })

const defaultInitialState: CompetitionState = new DefaultInitialState()


export const competitionReducer = (
  state: CompetitionState = defaultInitialState,
  action: AnyAction,
): CompetitionState => {
  switch (action.type) {
    case CompetitionActionTypes.SET_MARKET_CUMULATIVE_VOLUME: {
      return state.set('cumulativeVolume', action.payload)
    }
    case CompetitionActionTypes.SET_VOLUME_LEADERBOARD_DATA: {
      return state.set('volumeData', action.payload)
    }
    case CompetitionActionTypes.SET_PNL_LEADERBOARD_DATA: {
      return state.set('pnlData', action.payload)
    }
    case CompetitionActionTypes.SET_LOTTERY_LEADERBOARD_DATA: {
      return state.set('lotteryData', action.payload)
    }
    case CompetitionActionTypes.SET_LEAGUE_LEADERBOARD_DATA: {
      return state.set('leagueData', action.payload)
    }
    case CompetitionActionTypes.SET_COSMOS_DATA_MAP_LEADERBOARD_DATA: {
      return state.set('cosmosDataSubCompetitionIdMap', action.payload)
    }
    case CompetitionActionTypes.SET_CURRENT_COMPETITION: {
      return state.set('currentCompetition', action.payload)
    }
    case CompetitionActionTypes.SET_PAST_COMPETITION: {
      return state.set('pastCompetition', action.payload)
    }
    case CompetitionActionTypes.SET_UPCOMING_COMPETITION: {
      return state.set('upcomingCompetition', action.payload)
    }
    case CompetitionActionTypes.SET_SUBCOMPETITIONS_MAP: {
      return state.set('subCompetitionsMap', action.payload)
    }
    case CompetitionActionTypes.SET_ACTIVE_STEP: {
      return state.set('activeStep', action.payload)
    }
    case CompetitionActionTypes.SET_DISABLE_OPEN_SIGNUP: {
      localStorage.setItem(CompetitionActionTypes.SET_DISABLE_OPEN_SIGNUP, JSON.stringify(action.payload))
      return state.set('disableOpen', action.payload)
    }
    case CompetitionActionTypes.SET_IS_REGISTERED: {
      return state.set('isRegistered', action.payload)
    }
    case CompetitionActionTypes.SET_REGISTERED_SUBCOMPETITION_ID: {
      return state.set('registeredSubCompetitionId', action.payload)
    }
    case CompetitionActionTypes.SET_CARBON_CREDITS_ROOT: {
      return state.set('carbonCreditsRootHash', action.payload)
    }
    case CompetitionActionTypes.SET_CARBON_CREDITS_SEASON_INFO: {
      return state.set('carbonCreditsSeasons', action.payload)
    }
    case CompetitionActionTypes.SET_ONGOING_SEASON_INFO: {
      return state.set('ongoingSeason', action.payload)
    }
    case CompetitionActionTypes.SET_TOTAL_ACCUMULATED_REWARDS: {
      return state.set('totalAccumulatedRewards', action.payload)
    }
    case CompetitionActionTypes.SET_UNCLAIMED_REWARDS: {
      return state.set('unclaimedRewards', action.payload)
    }
    case CompetitionActionTypes.SET_ACCUMULATED_SEASONS_REWARDS: {
      return state.set('accumulatedSeasonsRewards', action.payload)
    }
    case CompetitionActionTypes.SET_CARBON_CREDITS_LEADERBOARD_PNL: {
      return state.set('leaderboardPnl', action.payload)
    }
    case CompetitionActionTypes.SET_MERKLE_TREE: {
      return state.set('merkleTree', action.payload)
    }
    case CompetitionActionTypes.SET_USER_DEMEX_POINTS: {
      return state.set('userDemexPoints', action.payload)
    }
    case CompetitionActionTypes.SET_DEMEX_POINTS_LEADERBOARD: {
      return state.set('demexPointsLeaderboard', action.payload)
    }
    case CompetitionActionTypes.SET_HIDE_DEMEX_POINTS_GUIDE: {
      const hideDemexPointsGuide = state.hideDemexPointsGuide
      if (!hideDemexPointsGuide.includes(action.payload)) {
        hideDemexPointsGuide.push(action.payload)
      }
      localStorage.setItem(CompetitionActionTypes.SET_HIDE_DEMEX_POINTS_GUIDE, JSON.stringify(hideDemexPointsGuide))
      return state.set('hideDemexPointsGuide', hideDemexPointsGuide)
    }
    case CompetitionActionTypes.SET_USER_TRADING_VOLUME_CAMPAIGN: {
      return state.set('userTradingVolumeCampaign', action.payload)
    }
    case CompetitionActionTypes.SET_LOSS_PROTECTION_LEADERBOARD: {
      return state.set('lossProtectionLeaderboard', action.payload)
    }
    case CompetitionActionTypes.SET_SHOW_GUIDE: {
      return state.set('showLossProtectionGuide', action.payload)
    }
    case CompetitionActionTypes.SET_SHOW_REGISTRATION: {
      return state.set('showLossProtectionRegistration', action.payload)
    }

    case CompetitionActionTypes.SET_MEME_TRADING_LEADERBOARD: {
      return state.set('memeTradingLeaderboard', action.payload)
    }
    case CompetitionActionTypes.SET_SHOW_MEME_TRADING_GUIDE: {
      return state.set('showMemeTradingGuide', action.payload)
    }
    case CompetitionActionTypes.SET_SHOW_MEME_TRADING_REGISTRATION: {
      return state.set('showMemeTradingRegistration', action.payload)
    }
    default:
      return state
  }
}
