import BigNumber from 'bignumber.js'
import { BlockchainUtils, Carbon, Models, TypeUtils } from 'carbon-js-sdk'
import { Token, TokenBalance } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { GetFeeQuoteResponse } from 'carbon-js-sdk/lib/hydrogen/feeQuote' // eslint-disable-line
import { Dayjs } from 'dayjs'
import { RecordOf } from 'immutable'

import { AdditionalIbcToken, TransferMethod } from 'js/constants/TransferOptions'
import { BN_ZERO } from 'js/utils/number'
import { SmallNumResult } from 'js/utils/strings'

import { Market } from '../exchange/types'

export type GetFeeQuoteReponseWithoutFees = Omit<Omit<Omit<GetFeeQuoteResponse, 'create_wallet_fee'>, 'deposit_fee'>, 'withdrawal_fee'>

// export interface WalletFee extends GetFeeQuoteReponseWithoutFees {
//   createWalletFeeBN: BigNumber
//   depositFeeBN: BigNumber
//   withdrawalFeeBN: BigNumber
// }

export interface RetrieveWalletFeesPayload {
  denoms: string[]
  carbonFeeDenom: string
}

export interface WalletFee {
  depositFeeBN: BigNumber
  withdrawalFeeBN: BigNumber
  tokenDenom: string
  feeDenom: string // only axelar fees will have this
  expiresAt?: Dayjs
}

export interface WalletFeesMap {
  // denom indexed
  [index: string]: WalletFee
}

export interface WalletBalance {
  total?: BigNumber

  available?: BigNumber

  order?: BigNumber
  position?: BigNumber

  unavailable?: BigNumber

  delegated?: BigNumber
  unbonding?: BigNumber

  pool?: BigNumber // spot LP
  poolLocked?: BigNumber // spot LP committed

  supply?: BigNumber // nitron
  supplyLocked?: BigNumber // nitron collateral

  perpPool?: BigNumber // perp LP
  perpPoolLocked?: BigNumber // perp LP staking incentives

  feeReserved?: BigNumber // reserved for fee pocket
}
export type WalletBalanceField = keyof WalletBalance

export interface AdjustedBalance extends WalletBalance {
  tokenDp: number
}

export interface UserPortfolio extends Partial<WalletBalance> {

}

export interface TokenContractButtonPopUpState {
  popupType?: string
  link?: string
  contractAddress?: string
}

export interface SelectedToken extends Token {
  isTokenGroup: boolean
}

export interface TokenBasicInfo {
  assetFullName: string,
  assetName: string,
  decimals: number,
  denom: string,
  blockchain: BlockchainUtils.BlockchainV2,
  isWrappedToken: boolean
  isPoolToken: boolean
  isPerpPoolToken: boolean
  isGroupToken: boolean
  isPartOfGroup: boolean

  isDepositHidden?: boolean
  isWithdrawHidden?: boolean
}

export interface TokenCDPInfo {
  assetTitle: string,
  underlyingDenom: string,
  underlyingDecimals: number,
  cdpRatio: BigNumber,
  isCdpToken: boolean
  isLendable: boolean
  isBorrowable: boolean
  isLendableCap: boolean
  isBorrowableCap: boolean
}

export interface TokenExtraBlockchains {
  extraBlockchains: string[],
  noOfExtraBlockChains: number,
  tradableMarkets: Market[],
}

export interface TokenProperties {
  tokenBasicInfo: TokenBasicInfo
  tokenCDPInfo: TokenCDPInfo
  tokenExtraBlockchains: TokenExtraBlockchains
}

export type DenomValue = [string, BigNumber]

export type BridgeTransferMethods = {
  [key in BlockchainUtils.BlockchainV2]: TransferMethod[]
}

export type BlockchainToDenomMap = {
  [key in BlockchainUtils.BlockchainV2]: string
}

export interface BlockchainsMap {
  // For non-grouped tokens
  singleTokenChains?: BlockchainToDenomMap
  // For grouped tokens, so we can list the blockchains for each groupTokenSymbol 
  groupTokenChains?: {
    [symbol: string]: BlockchainToDenomMap
  }
}

export interface SetChainSelectOptionsPayload {
  blockchainDenomMap: BlockchainToDenomMap
  blockchainList: string[]
  isWithdrawal: boolean
  isSmartWalletCompatible: boolean
}

export interface NetworkWithFee {
  blockchain: BlockchainUtils.BlockchainV2
  tokenStandard: string
  feeUsdValue: BigNumber | undefined
  duration: string
}

export type NetworkWithFeesArr = NetworkWithFee[]

export interface ChainSelectOptions {
  optionList: NetworkWithFeesArr
  denomMap: BlockchainToDenomMap
}

export enum TransferSource {
  Wallet = 'Wallet',
  DemexUser = 'Demex User'
}

export interface TransferOption {
  source?: TransferSource
  blockchain?: BlockchainUtils.BlockchainV2
  optionDenom?: string

  // packet forwarding details
  additionalTokenInfo?: AdditionalIbcToken
}

export interface TransferFormErrors {
  address?: string
  amount?: string
  fees?: string
}

export interface TransferForm {
  address: string
  addressLabel: string
  addressToken?: string

  amount: string
  amountBN: BigNumber

  sliderValue: BigNumber

  memo: string

  errors: TransferFormErrors

  // For mobile submit section
  amtDisplay?: SmallNumResult
  usdAmtDisplay?: SmallNumResult
  onSubmit?: () => void
  submitBtnLoading?: boolean
  submitBtnDisabled?: boolean
  transferDenom?: string
}

type AmountParamsType = Omit<TransferForm, 'address' | 'addressLabel' | 'memo' | 'errors'>

export const amountResetParams: AmountParamsType = {
  amount: '',
  amountBN: BN_ZERO,

  sliderValue: BN_ZERO,

  amtDisplay: undefined,
  usdAmtDisplay: undefined,
}

type AddressParamsType = Omit<TransferForm, 'amount' | 'amountBN' | 'sliderValue' | 'memo' | 'errors'>

export const addressResetParams: AddressParamsType = {
  address: '',
  addressLabel: '',
  addressToken: undefined,
}

export const defaultTransferForm: TransferForm = {
  ...amountResetParams,
  ...addressResetParams,
  memo: '',
  errors: {},
}

export interface TxDetails {
  amount: SmallNumResult | BigNumber
  denom: string
  sourceBlockchain: BlockchainUtils.BlockchainV2
  destinationBlockchain: BlockchainUtils.BlockchainV2
  txHash: string
  withdrawalAddress?: string // for withdraw txs only
}

export interface WalletBalanceStateProps {
  // balances include carbon + evm balance
  readonly evmBalances: TypeUtils.SimpleMap<TokenBalance>
  readonly carbonBalances: TypeUtils.SimpleMap<TokenBalance>
  readonly isUSD: boolean
  readonly profile: Carbon.Profile.Profile | null

  // Deposit + Withdraw methods for each blockchain
  readonly bridgeTransferMethods: BridgeTransferMethods
  // List of blockchains for Deposit + Withdraw UI (for blockchain slide up drawer)
  readonly chainSelectOptions: ChainSelectOptions
  readonly chainSelectOpen: boolean

  // Transfer params
  readonly transferOption: TransferOption
  readonly groupTokenOption: string
  readonly selectedToken: SelectedToken | null
  transferForm: TransferForm
  readonly initiatedTxDetails?: TxDetails

  readonly walletFees: WalletFeesMap
  readonly hasSufficientFees: boolean | null
  readonly neoTokenBalance: BigNumber

  // only for swth delegations
  // use alliance delegations for other denoms
  readonly rawDelegations: Models.Staking.DelegationResponse[] | null
  readonly rawUnbondingDelegations: Models.Staking.UnbondingDelegation[] | null

  readonly balances: Record<string, WalletBalance>
  readonly adjustedBalances: Record<string, AdjustedBalance>

  // totals in USD terms
  readonly portfolio: UserPortfolio

  // redemption rates for derived tokens (cibt/, clpt/, cplt/)
  // { string: [denom, rate][] }
  readonly derivedTokenRedemption: Record<string, DenomValue[]>

  // balance denoms sorted by USD value
  readonly nonZeroBalanceDenoms?: string[]

  readonly tokenContractButtonPopUpState: TokenContractButtonPopUpState

  readonly adjustedStake: TypeUtils.SimpleMap<BigNumber>
  readonly tokenProperties: TypeUtils.SimpleMap<TokenProperties>

}

export type WalletBalanceState = RecordOf<WalletBalanceStateProps>

export const WalletBalanceActionTypes = {
  ADD_WALLET_BALANCE: '@walletBalance/ADD_WALLET_BALANCE',
  SET_WALLET_BALANCE: '@walletBalance/SET_WALLET_BALANCE',
  SET_BALANCES: '@walletBalance/SET_BALANCES',
  SET_ADJUSTED_BALANCES: '@walletBalance/SET_ADJUSTED_BALANCES',
  SET_PORTFOLIO: '@walletBalance/SET_PORTFOLIO',
  SET_NON_ZERO_BALANCE_DENOMS: '@walletBalance/SET_NON_ZERO_BALANCE_DENOMS',
  SET_DERIVED_TOKEN_REDEMPTION: '@walletBalance/SET_DERIVED_TOKEN_REDEMPTION',
  SET_CARBON_WALLET_BALANCE: '@walletBalance/SET_CARBON_WALLET_BALANCE',
  SET_EVM_WALLET_BALANCE: '@walletBalance/SET_EVM_WALLET_BALANCE',
  CLEAR_WALLET_BALANCE: '@walletBalance/CLEAR_WALLET_BALANCE',

  CHECK_SELECT_TOKEN: '@walletBalance/CHECK_SELECT_TOKEN',
  SET_SELECTED_TOKEN: '@walletBalance/SET_SELECTED_TOKEN',
  SELECT_TOKEN: '@walletBalance/SELECT_TOKEN',
  SELECT_GROUP_TOKEN_OPTION: '@walletBalance/SELECT_GROUP_TOKEN_OPTION',
  SET_SELECTED_TRANSFER_SOURCE: '@walletBalance/SET_SELECTED_TRANSFER_SOURCE',
  SET_SELECTED_BLOCKCHAIN: '@walletBalance/SET_SELECTED_BLOCKCHAIN',
  SET_SELECTED_OPTION_DENOM: '@walletBalance/SET_SELECTED_OPTION_DENOM',
  SET_SELECTED_DENOM_ADDITIONAL_TOKEN_INFO: '@walletBalance/SET_SELECTED_DENOM_ADDITIONAL_TOKEN_INFO',
  UPDATE_TRANSFER_FORM_INPUTS: '@walletBalance/UPDATE_TRANSFER_FORM_INPUTS',
  SET_INITIATED_TX_DETAILS: '@walletBalance/SET_INITIATED_TX_DETAILS',

  SET_BRIDGE_TRANSFER_METHODS: '@walletBalance/SET_BRIDGE_TRANSFER_METHODS',
  RETRIEVE_CHAIN_SELECT_OPTIONS: '@walletBalance/RETRIEVE_CHAIN_SELECT_OPTIONS',
  SET_CHAIN_SELECT_OPTIONS: '@walletBalance/SET_CHAIN_SELECT_OPTIONS',
  SET_CHAIN_SELECT_OPEN: '@walletBalance/SET_CHAIN_SELECT_OPEN',

  RETRIEVE_WALLET_FEES_LIST: '@walletBalance/RETRIEVE_WALLET_FEES_LIST',
  SET_WALLET_FEES: '@walletBalance/SET_WALLET_FEES',
  CLEAR_WALLET_FEES: '@walletBalance/CLEAR_WALLET_FEES',
  SET_HAS_SUFFICIENT_FEES: '@walletBalance/SET_HAS_SUFFICIENT_FEES',

  QUERY_NEO_TOKEN_BALANCE: '@walletBalance/QUERY_NEO_TOKEN_BALANCE',
  SET_NEO_TOKEN_BALANCE: '@walletBalance/SET_NEO_TOKEN_BALANCE',

  // TODO: remove
  SET_ACCOUNT_DELEGATIONS: '@walletBalance/SET_ACCOUNT_DELEGATIONS',

  SET_RAW_DELEGATIONS: '@walletBalance/SET_RAW_DELEGATIONS',
  SET_RAW_UNBONDING_DELEGATIONS: '@walletBalance/SET_RAW_UNBONDING_DELEGATIONS',
  SET_TOKEN_CONTRACT_BUTTON_POPUP_STATE: '@walletBalance/SET_TOKEN_CONTRACT_BUTTON_POPUP_STATE',

  SET_TOKEN_PROPERTIES: '@walletBalance/SET_TOKEN_PROPERTIES',
  SET_ENOUGH_TOKENS_FOR_NETWORK_FEE: '@walletBalance/SET_ENOUGH_TOKENS_FOR_NETWORK_FEE',
  SET_LAST_INSUFFICIENT_FEE_TOAST: '@walletBalance/SET_LAST_INSUFFICIENT_FEE_TOAST',
}

export const walletBalanceTaskNames: TypeUtils.SimpleMap<string> = {
  updateBalances: 'update-balances',
  userBalanceGraph: 'user-balance-graph',
  checkSelectToken: 'check-select-token',
}
