import { SvgIcon, Theme, Typography, TypographyProps, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React, { Fragment, PropsWithChildren, useMemo } from "react"
import { useSelector } from "react-redux"

import { getPools } from "js/state/modules/liquidityPool/selectors"
import { getTokenProperties } from "js/state/modules/walletBalance/selectors"
import { truncateStr } from "js/utils"
import { isSafariDevice, isWindowsDevice } from "js/utils/environment"

import { ReactComponent as GroupedTokenIndicator } from 'assets/GroupedTokenIndicator.svg'

interface CustomProps extends PropsWithChildren {
  denom?: string
  groupedIndicatorTokenClass?: string
  showAssetTitle?: boolean
  symbolOverride?: string
  showIndivPoolAssets?: boolean
  symbolClass?: string
  isTruncateAsset?: boolean
}

type TokenSymbolProps = TypographyProps<any> & CustomProps

const TokenSymbol: React.FC<TokenSymbolProps> = (props: TokenSymbolProps) => {
  const { children, denom, isTruncateAsset = false, groupedIndicatorTokenClass, showAssetTitle, symbolOverride, showIndivPoolAssets, className, symbolClass, ...rest } = props
  const isSafari = isSafariDevice()
  const isWindows = isWindowsDevice()
  const classes = useStyles()
  const tokenProperties = useSelector(getTokenProperties)
  const pools = useSelector(getPools)

  const tokenProperty = tokenProperties[denom]
  const isGroupedToken = tokenProperty?.tokenBasicInfo.isGroupToken
  const assetName = tokenProperty?.tokenBasicInfo.assetName ?? ''
  const assetTitle = tokenProperty?.tokenCDPInfo.assetTitle ?? ''
  const isPoolToken = tokenProperty?.tokenBasicInfo.isPoolToken
  const symbol = showAssetTitle ? assetTitle : assetName
  const indivPool = pools[denom]
  const displaySymbol = symbolOverride ? symbolOverride : symbol

  const truncateAssetName = useMemo(() => {
    if(displaySymbol.includes('_') && isTruncateAsset) {
      const strAssetNameSplit = displaySymbol.split('_')
      return (
        <React.Fragment>
          <span>{strAssetNameSplit[0]}_</span>
          <br/>
          <span>{strAssetNameSplit[1]}</span>
        </React.Fragment>
      )
    }

    return truncateStr(displaySymbol, 8, 8, '..')

  }, [displaySymbol, isTruncateAsset])

  return (showIndivPoolAssets && isPoolToken && !!indivPool) ? (
    <Fragment>
      <TokenSymbol {...props} denom={indivPool.denomA} />-<TokenSymbol {...props} denom={indivPool.denomB} />
    </Fragment>
  ) : (
    <Typography
      component="span"
      className={clsx(className, classes.root, { isSafari }, { isWindows })}
      {...rest}
    >
      {isSafari ? displaySymbol : (
        <span className={clsx(classes.symbolText, symbolClass)}>
          {truncateAssetName}
        </span>
      )}
      {isGroupedToken && (
        <SvgIcon
          className={clsx(classes.groupedTokenIndicator, groupedIndicatorTokenClass, { isSafari }, { isWindows })}
          component={GroupedTokenIndicator}
          viewBox="0 0 12 12"
        />
      )}
    </Typography>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    wordBreak: 'keep-all',
    '&.isSafari': {
      marginTop: 'unset',
    },
    '&.isWindows': {
      marginTop: 'unset',
    },
  },
  symbolText: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  groupedTokenIndicator: {
    height: '0.9em',
    width: '0.9em',
    fontSize: 'unset',
    marginLeft: '0.7px',
    marginTop: '0.1em',
    display: 'inline-block',
    verticalAlign: 'middle',
    overflow: 'visible',
    '& path': {
      fill: 'currentColor'
    },
    '&.isSafari': {
      marginTop: '0.1rem',
      display: 'unset',
      verticalAlign: 'unset',
    },
    '&.isWindows': {
      marginTop: '0',
    },
    [theme.breakpoints.down('sm')]: {
      '&.isSafari': {
        marginTop: '0'
      },
      '&.isWindows': {
        marginTop: '0',
      },
    },
  },
}))

export default TokenSymbol
