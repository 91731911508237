import { Hidden, IconButton, makeStyles, Radio, TableCell, TableRow, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Insights } from 'carbon-js-sdk'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LoadingIcon from 'js/components/Common/LoadingIcon'
import Tooltip from 'js/components/Exchange/Common/Tooltip'
import { setAutoSelectNode, setFormNode, showNodeInfoForm } from 'js/state/modules/app/actions'
import { getNet, getRatingLatency, getSelectedNodes } from 'js/state/modules/app/selectors'
import { StyleUtils } from 'js/utils'
import { shortenStr } from 'js/utils/strings'

import { ReactComponent as ArrowRightGradient } from 'assets/ArrowRightGradient.svg'
import { ReactComponent as Rating } from 'assets/Rating.svg'

interface Props {
  node: any
  isCustom: boolean
  selectingNode: any
  recommendedNode: Insights.NodeItem
  handleSelectNode: (node: any) => void
}

const NodeRow: React.FC<Props> = (props: Props) => {
  const { isCustom, node, recommendedNode, selectingNode, handleSelectNode } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  const ratingLatencies = useSelector(getRatingLatency)
  const indivRatingLatency = ratingLatencies[node.nodeId]
  const latency = indivRatingLatency?.latency ?? 0
  const rating = indivRatingLatency?.rating ?? 0
  const widthXs = useMediaQuery(theme.breakpoints.down('xs'))
  const network = useSelector(getNet)
  const selectedNodes = useSelector(getSelectedNodes)
  const selectedNodeId = React.useMemo(() => selectedNodes?.[network]?.nodeId, [selectedNodes, network])

  const handleViewNodeInfo = (node: any) => {
    dispatch(showNodeInfoForm())
    dispatch(setFormNode({
      moniker: node.moniker,
      rpcUrl: node.rpcUrl,
      restUrl: node.restUrl,
      wsUrl: node.wsUrl,
      faucetUrl: node.faucetUrl,
      insightsUrl: node.insightsUrl,
      tmWsUrl: node.tmWsUrl,
    }))
  }

  interface UptimeStyle {
    bad?: boolean,
    good?: boolean,
    ok?: boolean
  }
  const colorizedUptime = (uptime: string = '0') => {
    const uptimeNum = parseFloat(uptime)
    const style: UptimeStyle = {}
    if (uptimeNum < 60) style.bad = true
    else if (uptimeNum < 90) style.ok = true
    else style.good = true
    return style
  }

  const colorizedLatency = (latency: number = 999) => {
    const style: UptimeStyle = {}
    if (latency > 300) style.bad = true
    else if (latency > 100) style.ok = true
    else style.good = true
    return style
  }

  const selectRowNode = (node: any) => {
    dispatch(setAutoSelectNode(false))
    handleSelectNode(node)
  }

  const monikerDisplay = React.useMemo(() => (
    widthXs ? shortenStr(node.moniker, 35) : shortenStr(node.moniker, 25)
  ), [widthXs, node])

  return (
    <TableRow
      className={clsx(classes.row, { [classes.notSelected]: node.nodeId !== selectedNodeId })}
      hover
    >
      <TableCell className={classes.filler} />
      <TableCell className={classes.radioCell} onClick={() => selectRowNode(node)}>
        {selectingNode?.nodeId === node.nodeId ? (
          <div className={classes.radio}>
            <LoadingIcon size={16} />
          </div>
        ) : (
          <Radio
            size="small"
            checked={node.nodeId === selectedNodeId}
            classes={{ root: classes.radio, checked: classes.radioChecked }}
            onChange={() => selectRowNode(node)}
          />
        )}
      </TableCell>
      <TableCell onClick={() => selectRowNode(node)}>
        <div className={classes.nodeDetailsBox}>
          <div className={classes.nodeNameBox}>
            <Typography className={classes.name}>
              {monikerDisplay}
            </Typography>
            {recommendedNode?.nodeId === node.nodeId && (
              <div className={classes.recommendedTag}>Best</div>
            )}
            {isCustom && (
              <div className={classes.customTag}>Custom</div>
            )}
          </div>
          <Hidden mdDown>
            <Typography className={classes.url}>{node.rpcUrl}</Typography>
          </Hidden>
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.tooltipWrapper}>
          {!isCustom && (
            <React.Fragment>
              <Tooltip
                placement="top"
                arrow={false}
                title={(
                  <React.Fragment>
                    <div className={classes.tooltip}>
                      <div className={classes.attributeRow}>
                        <Typography className={classes.attribute}>Latency</Typography>
                        <Typography className={clsx(classes.value, { ...colorizedLatency(latency) })}>
                          {latency}ms
                        </Typography>
                      </div>
                      <div className={classes.attributeRow}>
                        <Typography className={classes.attribute}>WS Uptime (24H)</Typography>
                        <Typography className={clsx(classes.value, { ...colorizedUptime(node?.wsUptime) })}>
                          {node?.wsUptime}%
                        </Typography>
                      </div>
                      <div className={classes.attributeRow}>
                        <Typography className={classes.attribute}>RPC Uptime (24H)</Typography>
                        <Typography className={clsx(classes.value, { ...colorizedUptime(node?.rpcUptime) })}>
                          {node?.rpcUptime}%
                        </Typography>
                      </div>
                      <div className={classes.attributeRow}>
                        <Typography className={classes.attribute}>Insight Uptime (24H)</Typography>
                        <Typography className={clsx(classes.value, { ...colorizedUptime(node?.insightUptime) })}>
                          {node?.insightUptime}%
                        </Typography>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              >
                <span>
                  <Rating
                    className={clsx(classes.indicator, {
                      five: rating >= 0.8,
                      four: rating >= 0.6 && rating < 0.8,
                      three: rating >= 0.4 && rating < 0.6,
                      two: rating >= 0.2 && rating < 0.4,
                      one: rating < 0.2,
                    })}
                  />
                </span>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
      </TableCell>
      <TableCell>
        <IconButton
          classes={{
            root: classes.iconButton,
          }}
          onClick={() => handleViewNodeInfo(node)}
        >
          <ArrowRightGradient />
        </IconButton>
      </TableCell>
      <TableCell className={classes.filler} />
    </TableRow>
  )
}

const useStyles = makeStyles((theme) => ({
  filler: {
    width: theme.spacing(2.25),
  },
  radio: {
    '& svg': {
      width: '1rem',
      height: '1rem',
      '& path': {
        fill: theme.palette.text.secondary,
      },
    },
    margin: theme.spacing(0, 1, 0, 0),
    padding: 0,
  },
  radioChecked: {
    '& svg': {
      '& path': {
        fill: theme.palette.text.demexSolid,
      },
    },
  },
  nodeDetailsBox: {
    wordBreak: 'break-all',
    [theme.breakpoints.up('lg')]: {
      width: '9.5rem',
    },
    [theme.breakpoints.down('md')]: {
      width: '15.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '10.6rem',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vw - 120px)',
    },
    userSelect: 'none',
  },
  nodeNameBox: {
    display: 'flex',
  },
  recommendedTag: {
    ...theme.typography.body4,
    color: '#FFFFFF',
    background: 'linear-gradient(270deg, #482BFF 0%, #007AFF 100%)',
    margin: theme.spacing(0, 1),
    borderRadius: 4,
    padding: theme.spacing(0.25, 0.5),
    textAlign: 'center',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0.75),
    },
  },
  customTag: {
    ...theme.typography.body4,
    color: '#FFFFFF',
    background: 'linear-gradient(90deg, #FFA800 0%, #FF5107 100%)',
    margin: theme.spacing(0, 1),
    borderRadius: 4,
    padding: theme.spacing(0.25, 0.5),
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  name: {
    ...theme.typography.body3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
  },
  url: {
    ...theme.typography.body4,
    paddingTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    padding: theme.spacing(0.25),
    display: 'flex',
    flexDirection: 'column',
  },
  tooltipClass: {
    backgroundColor: theme.palette.background.primary,
    border: `1px solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  attributeRow: {
    padding: theme.spacing(0.375, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
  attribute: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(4),
  },
  value: {
    ...theme.typography.body4,
    textTransform: 'capitalize',
    '&.bad': {
      color: theme.palette.error.main,
    },
    '&.ok': {
      color: theme.palette.warning.main,
    },
    '&.good': {
      color: theme.palette.success.main,
    },
  },
  icon: {
    width: '1rem',
    height: '1rem',
    maxHeight: 'unset',
    marginLeft: 0,
    '& path': {
      stroke: theme.palette.text.secondary,
    },
    '&:hover': {
      '& path': {
        stroke: theme.palette.type === 'light' ? theme.palette.text.demexSolidHover : theme.palette.text.animated,
        ...StyleUtils.hoverAnimationDuration(),
      },
    },
  },
  iconButton: {
    padding: 0,
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  indicator: {
    cursor: 'help',
    margin: theme.spacing(0, 1),
    verticalAlign: 'middle',
    '& rect': {
      fill: theme.palette.text.disabled,
    },
    '&.five': {
      '& rect': {
        fill: theme.palette.success.main,
      },
    },
    '&.four': {
      '& rect': {
        fill: theme.palette.success.main,
        '&:last-child': {
          fill: theme.palette.text.disabled,
        },
      },
    },
    '&.three': {
      '& rect': {
        fill: theme.palette.warning.main,
        '&:nth-child(n+4)': {
          fill: theme.palette.text.disabled,
        },
      },
    },
    '&.two': {
      '& rect': {
        fill: theme.palette.warning.main,
        '&:nth-child(n+3)': {
          fill: theme.palette.text.disabled,
        },
      },
    },
    '&.one': {
      '& rect:first-child': {
        fill: theme.palette.error.main,
      },
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0.75),
    },
  },
  row: {
    '& td': {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(0),
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
          paddingRight: 0,
        },
      },
    },
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      '& td:first-child, & td:last-child': {
        border: 'none',
      },
      '& td:last-child': {
        paddingLeft: 0,
      },
    },
  },
  arrowRight: {
    width: '1rem',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    maxWidth: '3.5rem',
  },
  radioCell: {
    [theme.breakpoints.down('md')]: {
      '& span': {
        paddingLeft: theme.spacing(0.5),
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& span': {
        paddingLeft: theme.spacing(0.25),
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(2),
    }
  },
}))

export default NodeRow
