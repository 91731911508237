import { OrderModule } from 'carbon-js-sdk'
import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { isMarket } from 'js/models/Order'
import { getDefaultTimeInForce } from 'js/utils/order'

import { OrderManagerActionTypes, OrderManagerState, OrderManagerStateProps, defaultOrderFormState } from './types'

const showOrderDetails = localStorage.getItem(OrderManagerActionTypes.SET_SHOW_ORDER_DETAILS)

export const DefaultInitialState:
  Record.Factory<OrderManagerStateProps> = Record<OrderManagerStateProps>({
    orderForm: defaultOrderFormState, // Source of truth when editing form
    showOrderDetails: showOrderDetails !== 'false',
    openTpSlDialog: false,
    takeProfitTriggerErrMsg: '',
    takeProfitPriceErrMsg: '',
    stopLossTriggerErrMsg: '',
    stopLossPriceErrMsg: '',
    feeToken: 'cgt/1', // default fee token is USD
    feeQuantity: '0',
    alertOpen: false,
    reservedSettingsOpen: false,
  })

export const OrderManagerReducer = (
  state: OrderManagerState = new DefaultInitialState(),
  action: AnyAction,
): OrderManagerState => {
  switch (action.type) {
    case OrderManagerActionTypes.CLEAR_ORDER_FORM: {
      const newDefaultOrderType = state.orderForm.orderType ?? OrderModule.OrderType.Limit
      const newState = new DefaultInitialState()
        .mergeIn(['orderForm'], {
          triggerType: state.orderForm.triggerType ?? OrderModule.TriggerType.LastPrice,
          side: state.orderForm.side ?? OrderModule.OrderSide.Buy,
          orderType: newDefaultOrderType,
          timeInForce: getDefaultTimeInForce(isMarket(newDefaultOrderType)),
          isTakeProfitStopLoss: state.orderForm.isTakeProfitStopLoss,
        })
        .set('showOrderDetails', state.showOrderDetails)
      return newState
    }
    case OrderManagerActionTypes.SET_ORDER_FORM_INPUTS: {
      return state.set('orderForm', action.payload)
    }
    case OrderManagerActionTypes.SET_SHOW_ORDER_DETAILS: {
      localStorage.setItem(OrderManagerActionTypes.SET_SHOW_ORDER_DETAILS, action.payload)
      return state.set('showOrderDetails', action.payload)
    }
    case OrderManagerActionTypes.SELECT_FEE_TOKEN: {
      return state.set('feeToken', action.payload)
    }
    case OrderManagerActionTypes.SET_FEE_QUANTITY: {
      return state.set('feeQuantity', action.payload)
    }
    case OrderManagerActionTypes.CLEAR_SWTH_FEE_FORM: {
      const newState = state
      return newState.set('feeToken', null)
        .set('feeQuantity', '0')
    }
    case OrderManagerActionTypes.SET_ALERT_DIALOG_CLOSE: {
      return state.set('alertOpen', false)
    }
    case OrderManagerActionTypes.SET_ALERT_DIALOG_OPEN: {
      return state.set('alertOpen', true)
    }
    case OrderManagerActionTypes.SET_RESERVED_SETTINGS_CLOSE: {
      return state.set('reservedSettingsOpen', false)
    }
    case OrderManagerActionTypes.SET_RESERVED_SETTINGS_OPEN: {
      return state.set('reservedSettingsOpen', true)
    }
    case OrderManagerActionTypes.SET_OPEN_TP_SL_DIALOG: {
      return state.set('openTpSlDialog', action.payload)
    }
    case OrderManagerActionTypes.SET_TAKE_PROFIT_TRIGGER_ERR_MSG: {
      return state.set('takeProfitTriggerErrMsg', action.payload)
    }
    case OrderManagerActionTypes.SET_TAKE_PROFIT_PRICE_ERR_MSG: {
      return state.set('takeProfitPriceErrMsg', action.payload)
    }
    case OrderManagerActionTypes.SET_STOP_LOSS_TRIGGER_ERR_MSG: {
      return state.set('stopLossTriggerErrMsg', action.payload)
    }
    case OrderManagerActionTypes.SET_STOP_LOSS_PRICE_ERR_MSG: {
      return state.set('stopLossPriceErrMsg', action.payload)
    }
    default:
      return state
  }
}
