import { TypeUtils } from 'carbon-js-sdk'
import { Connection, ExternalTokenMapping } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/bridge/bridge'
import { createSelector } from 'reselect'

import { RootState } from '../rootReducer'

export function getExternalTokens(state: RootState): TypeUtils.SimpleMap<ExternalTokenMapping> {
  return state.bridge.externalTokens
}

export function getBridgeConnections(state: RootState): TypeUtils.SimpleMap<Connection> {
  return state.bridge.connections
}

export const getExternalTokenByDenom = (denom: string) =>
  createSelector([getExternalTokens], (externalTokens: TypeUtils.SimpleMap<ExternalTokenMapping>): ExternalTokenMapping | undefined => {
    return externalTokens[denom]
  }
  )

export const getExternalConnectionById = (connectionId: string) =>
  createSelector([getBridgeConnections], (connections: TypeUtils.SimpleMap<Connection>): Connection | undefined => {
    return connections[connectionId]
  }
  )