import { CarbonSDK, CarbonSignerTypes, ProviderAgent, TypeUtils } from 'carbon-js-sdk'
import { ExtendedChainInfo } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved
import { ethers } from 'ethers'
import { Middleware } from 'redux'
import { PayloadAction, TypeConstant } from 'typesafe-actions'

import { StorageKey, StorageVersion } from 'js/constants/app'
import { saveJwt } from 'js/utils/auth'

import { AccountActionTypes } from '../modules/account/types'
import { AppActionTypes } from '../modules/app/types'
import { RootState } from '../modules/rootReducer'

const sessionStorageMiddleware: Middleware = (store) => (next) => (action: PayloadAction<TypeConstant, CarbonSDK | TypeUtils.SimpleMap<ExtendedChainInfo>>) => {
  switch (action.type) {
    case AppActionTypes.SET_CARBON_SDK: {
      const sdk: CarbonSDK = action.payload as CarbonSDK
      const publicKeyBuffer = sdk.wallet?.publicKey
      const publicKeyString = publicKeyBuffer?.toString('base64')
      const jwt = sdk.wallet?.jwt

      if(jwt) saveJwt(jwt)

      if (sdk.wallet?.mnemonic) {
        sessionStorage.setItem(StorageKey.Mnemonic, sdk.wallet?.mnemonic)
      } else {
        sessionStorage.removeItem(StorageKey.Mnemonic)
      }
      if (!sdk.wallet?.isRainbowKit) {
        localStorage.removeItem(StorageKey.RainbowKitWalletKey)
      }
      if (sdk.wallet?.isRainbowKit && publicKeyString) {
        localStorage.setItem(StorageKey.RainbowKitWalletKey, JSON.stringify(true))
        localStorage.setItem(StorageKey.PublicKey, publicKeyString)
        if (sdk.wallet?.providerAgent) {
          localStorage.setItem(StorageKey.ConnectedWallet, sdk.wallet.providerAgent)
        }
      } else if (sdk.wallet?.signer.type === CarbonSignerTypes.Ledger) {
        localStorage.setItem(StorageKey.ConnectedWallet, 'ledger')
      } else if (sdk.wallet?.providerAgent === ProviderAgent.KeplrExtension) {
        localStorage.setItem(StorageKey.ConnectedWallet, ProviderAgent.KeplrExtension)
      } else if (sdk.wallet?.providerAgent === ProviderAgent.LeapExtension) {
        localStorage.setItem(StorageKey.ConnectedWallet, ProviderAgent.LeapExtension)
      } else if (sdk.wallet?.providerAgent === ProviderAgent.MetamaskExtension) {
        localStorage.setItem(StorageKey.PublicKey, publicKeyString ?? '')
        localStorage.setItem(StorageKey.ConnectedWallet, ProviderAgent.MetamaskExtension)
      } else if (sdk.wallet?.providerAgent === ProviderAgent.Metamask) {
        localStorage.setItem(StorageKey.ConnectedWallet, ProviderAgent.Metamask)
      } else if (sdk.wallet?.providerAgent === ProviderAgent.Web3Auth) {
        localStorage.setItem(StorageKey.ConnectedWallet, ProviderAgent.Web3Auth)
      } else if (sdk.wallet?.signer.type !== CarbonSignerTypes.PublicKey) {
        localStorage.removeItem(StorageKey.ConnectedWallet)
      }
      break
    }
    case AccountActionTypes.LOGOUT: {
      sessionStorage.removeItem(StorageKey.Mnemonic)
      localStorage.removeItem(StorageKey.ConnectedWallet)
      localStorage.removeItem(StorageKey.PublicKey)
      const cachedBIP44 = localStorage.getItem(StorageKey.CosmosLedgerHdPath)
      localStorage.removeItem(StorageKey.RainbowKitWalletKey)
      if (cachedBIP44) {
        localStorage.removeItem(StorageKey.CosmosLedgerHdPath)
      }
      break
    }
    case AppActionTypes.SET_CHAIN_INFO_MAP: {
      const state: RootState = store.getState()
      const hash = ethers.utils.sha256(Buffer.from(JSON.stringify(state.app.denomTraces), "utf8"))
      const chainInfoMap = action.payload as TypeUtils.SimpleMap<ExtendedChainInfo>
      const cachedChainInfoMap = {
        v: StorageVersion[StorageKey.ChainInfoMap],
        t: new Date().getTime(),
        h: hash,
        data: chainInfoMap,
      }
      sessionStorage.setItem(StorageKey.ChainInfoMap, JSON.stringify(cachedChainInfoMap))
    }
  }
  return next(action)
}

export const loadSessionStorageState = (previous: RootState) => {
  const state = { ...previous }

  const userMnemonic = sessionStorage.getItem(StorageKey.Mnemonic)
  const connectedWallet = localStorage.getItem(StorageKey.ConnectedWallet)
  const isRainbowKitWallet = localStorage.getItem(StorageKey.RainbowKitWalletKey)

  if (userMnemonic) {
    state.app = state.app.set('userMnemonic', userMnemonic)
  } else {
    state.app = state.app.set('userMnemonic', undefined)
  }

  if (connectedWallet) {
    state.app = state.app.set('connectedAgent', connectedWallet)
  } else {
    state.app = state.app.set('connectedAgent', undefined)
  }

  if (isRainbowKitWallet) {
    state.app = state.app.set('isRainbowKit', Boolean(isRainbowKitWallet))
  } else {
    state.app = state.app.set('isRainbowKit', false)
  }

  return state
}

export default sessionStorageMiddleware
