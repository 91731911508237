import BigNumber from 'bignumber.js'
import { BlockchainUtils, TypeUtils } from 'carbon-js-sdk'
import { Token, TokenBalance } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { Dayjs } from 'dayjs'
import { action } from 'typesafe-actions'

import { AdditionalIbcToken } from 'js/constants/TransferOptions'

import { BridgeTransferMethods, ChainSelectOptions, DenomValue, RetrieveWalletFeesPayload, SelectedToken, SetChainSelectOptionsPayload, TokenContractButtonPopUpState, TokenProperties, TransferForm, TransferSource, TxDetails, UserPortfolio, WalletBalance, WalletBalanceActionTypes, WalletBalanceStateProps, WalletFeesMap } from './types'

/** WEBSOCKET ONLY: DO NOT CALL FROM COMPONENT */
export const setWalletBalance = (payload: TypeUtils.SimpleMap<TokenBalance>) => action(
  WalletBalanceActionTypes.SET_WALLET_BALANCE,
  payload,
)

export const setEvmWalletBalance = (payload: TypeUtils.SimpleMap<TokenBalance>) => action(
  WalletBalanceActionTypes.SET_EVM_WALLET_BALANCE,
  payload,
)
export const setCarbonWalletBalance = (payload: TypeUtils.SimpleMap<TokenBalance>) => action(
  WalletBalanceActionTypes.SET_CARBON_WALLET_BALANCE,
  payload,
)
export const setBalances = (payload: TypeUtils.SimpleMap<WalletBalance>) => action(
  WalletBalanceActionTypes.SET_BALANCES,
  payload,
)
export const setAdjustedBalances = (payload: TypeUtils.SimpleMap<WalletBalance>) => action(
  WalletBalanceActionTypes.SET_ADJUSTED_BALANCES,
  payload,
)
export const setPortfolio = (payload: UserPortfolio) => action(
  WalletBalanceActionTypes.SET_PORTFOLIO,
  payload,
)
export const setNonZeroBalanceDenoms = (payload: string[]) => action(
  WalletBalanceActionTypes.SET_NON_ZERO_BALANCE_DENOMS,
  payload,
)
export const setDerivedTokenRedemptionRate = (payload: Record<string, DenomValue[]>) => action(
  WalletBalanceActionTypes.SET_DERIVED_TOKEN_REDEMPTION,
  payload,
)
export const clearWalletBalance = () => action(WalletBalanceActionTypes.CLEAR_WALLET_BALANCE)

export const checkSelectToken = (token: Token) => action(
  WalletBalanceActionTypes.CHECK_SELECT_TOKEN,
  token,
)

export const setSelectedToken = (token: SelectedToken) => action(
  WalletBalanceActionTypes.SET_SELECTED_TOKEN,
  token,
)

export const selectGroupTokenOption = (tokenSymbol: string | null) => action(
  WalletBalanceActionTypes.SELECT_GROUP_TOKEN_OPTION,
  tokenSymbol,
)

export const setBridgeTransferMethods = (methods: BridgeTransferMethods) => action(
  WalletBalanceActionTypes.SET_BRIDGE_TRANSFER_METHODS,
  methods,
)

export const retrieveChainSelectOptions = (payload: SetChainSelectOptionsPayload) => action(
  WalletBalanceActionTypes.RETRIEVE_CHAIN_SELECT_OPTIONS,
  payload,
)

export const setChainSelectOptions = (chainSelectOptions: ChainSelectOptions) => action(
  WalletBalanceActionTypes.SET_CHAIN_SELECT_OPTIONS,
  chainSelectOptions,
)

export const setChainSelectOpen = (open: boolean) => action(
  WalletBalanceActionTypes.SET_CHAIN_SELECT_OPEN,
  open,
)

// START: Selected Wallet Transfer Option
export const setSelectedTransferSource = (source: TransferSource) => action(
  WalletBalanceActionTypes.SET_SELECTED_TRANSFER_SOURCE,
  source,
)

export const setSelectedBlockchain = (selectedBlockchain: BlockchainUtils.BlockchainV2 | undefined) => action(
  WalletBalanceActionTypes.SET_SELECTED_BLOCKCHAIN,
  selectedBlockchain,
)

export const setSelectedOptionDenom = (optionDenom: string | undefined) => action(
  WalletBalanceActionTypes.SET_SELECTED_OPTION_DENOM,
  optionDenom,
)

export const setSelectedDenomAdditionalTokenInfo = (additionalTokenInfo: AdditionalIbcToken | undefined) => action(
  WalletBalanceActionTypes.SET_SELECTED_DENOM_ADDITIONAL_TOKEN_INFO,
  additionalTokenInfo,
)

export const updateTransferFormInputs = (transferForm: Partial<TransferForm>) => action(
  WalletBalanceActionTypes.UPDATE_TRANSFER_FORM_INPUTS,
  transferForm,
)

export const setInitiatedTxDetails = (txDetails: TxDetails | undefined) => action(
  WalletBalanceActionTypes.SET_INITIATED_TX_DETAILS,
  txDetails,
)
// END: Selected Wallet Transfer Option

// Create separate action so that query for multiple denoms will not be stopped by takeLatest
export const retrieveWalletFeesList = (payload: RetrieveWalletFeesPayload) => action(
  WalletBalanceActionTypes.RETRIEVE_WALLET_FEES_LIST,
  payload,
)

export const setWalletFees = (feeInfo: WalletFeesMap) => action(
  WalletBalanceActionTypes.SET_WALLET_FEES,
  feeInfo,
)

export const setHasSufficientFees = (hasSufficientFees: boolean) => action(
  WalletBalanceActionTypes.SET_HAS_SUFFICIENT_FEES,
  hasSufficientFees,
)

export const clearWalletFees = (feeDenom: string) => action(
  WalletBalanceActionTypes.CLEAR_WALLET_FEES,
  feeDenom,
)

export const setRawDelegations = (delegations: WalletBalanceStateProps["rawDelegations"]) => action(
  WalletBalanceActionTypes.SET_RAW_DELEGATIONS,
  delegations,
)
export const setRawUnbondingDelegations = (unbondings: WalletBalanceStateProps["rawUnbondingDelegations"]) => action(
  WalletBalanceActionTypes.SET_RAW_UNBONDING_DELEGATIONS,
  unbondings,
)

export const queryNeoTokenBalance = (address: string) => action(
  WalletBalanceActionTypes.QUERY_NEO_TOKEN_BALANCE,
  address,
)

export const setNeoTokenBalance = (balance: BigNumber) => action(
  WalletBalanceActionTypes.SET_NEO_TOKEN_BALANCE,
  balance,
)

export const setTokenContractButtonPopupState = (state: TokenContractButtonPopUpState) => action(
  WalletBalanceActionTypes.SET_TOKEN_CONTRACT_BUTTON_POPUP_STATE,
  state,
)

export const setTokenProperties = (state: TypeUtils.SimpleMap<TokenProperties>) => action(
  WalletBalanceActionTypes.SET_TOKEN_PROPERTIES,
  state,
)

export const setLastInsufficientFeeToast = (state: Dayjs | null) => action(
  WalletBalanceActionTypes.SET_LAST_INSUFFICIENT_FEE_TOAST,
  state,
)
