import { connect, getConnectors, reconnect } from '@wagmi/core'
import { CarbonSDK, CarbonWallet, CarbonWalletGenericOpts, RainbowKitAccount, SupportedEip6963Provider } from 'carbon-js-sdk'

import { StorageKey } from 'js/constants/app'
import { GET_WALLET_PROVIDER_TIMEOUT_MS, LOGIN_TIMEOUT_MS } from 'js/constants/login'
import { wagmiConfig } from 'js/utils'

import { runTimedTask } from './misc'

export const extractRecentConnectorId = () => {
  const rawConnectorId = localStorage.getItem(StorageKey.RecentConnectedWeb3WalletId) ?? ''
  return rawConnectorId.replace(/^"(.*)"$/, '$1')
}

export const connectRainbowKit = async (sdk: CarbonSDK, walletOpts: Partial<CarbonWalletGenericOpts>) => {
  const walletProviderAgent = localStorage.getItem(StorageKey.ConnectedWallet) ?? ''
  const recentConnectorId = extractRecentConnectorId()
  const publicKeyBase64 = localStorage.getItem(StorageKey.PublicKey) ?? undefined

  const connector = await runTimedTask(async (signal) => {
    let connector

    do {
      let connectors = getConnectors(wagmiConfig)
      connectors = connectors.filter((c: any) => {
        return (c.name.toLowerCase() === walletProviderAgent) || c.id === recentConnectorId
      })
      connector = connectors.find((c: any) => c.id === recentConnectorId)

      const wallet = await reconnect(wagmiConfig, { connectors: connector ? [connector] : connectors })
      connector = wallet?.[0]?.connector

      if (!connector && connectors.length > 0) {
        connector = connectors[connectors.length - 1]
        await connect(wagmiConfig, { connector })
      }

      await new Promise(resolve => setTimeout(resolve, 1000))
    } while (connector && !connector.getProvider)

    if (signal.aborted) return null

    return connector
  }, {
    abortMessage: 'Unable to detect wallet. Please ensure that your wallet is unlocked.',
    durationInMs: GET_WALLET_PROVIDER_TIMEOUT_MS
  })

  if (!connector) return null

  const connectedSdk = await runTimedTask(async (signal) => {
    const provider = await connector.getProvider()
    const rainbowKitInstance = new RainbowKitAccount(provider)

    const rainbowKitWalletOpts = {
      publicKeyBase64,
      walletProvider: walletProviderAgent.toLowerCase() as SupportedEip6963Provider
    }

    const connectedSDK = await sdk.connectWithRainbowKit(rainbowKitInstance, rainbowKitWalletOpts, walletOpts)

    if (signal.aborted) return null

    return connectedSDK
  }, {
    abortMessage: 'Wallet connection timed out. Please check your wallet extension.',
    durationInMs: LOGIN_TIMEOUT_MS,
  })

  return connectedSdk
}

export const isRainbowkitCheck = (wallet: CarbonWallet | undefined) => {
  return wallet?.isRainbowKit
}
