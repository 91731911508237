import { O3Wallet } from 'carbon-js-sdk'
import { Network } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved


export type O3NetworkMap = {
  Neo: string
  Neo3: string
}

export const NetworksMap: O3NetworkMap = {
  Neo: 'MainNet',
  Neo3: 'N3MainNet',
}

export const detectO3Wallet = async (network: Network) => {
  return await O3Wallet.instance({ network })
}



