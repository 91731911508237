import { ethers } from 'ethers'
import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { defaultDemexConfig, StorageKey } from 'js/constants/app'
import { HIDDEN_BANNER_DURATION } from 'js/constants/date'
import { feeDropdownItems } from 'js/constants/fees'
import { Page } from 'js/constants/login'
import { safeParseStoredValue } from 'js/utils/localstorage'

import { AppActionTypes, AppState, AppStateProps, TransactionStatus, UserProfile } from './types'

import { AccountActionTypes } from '../account/types'

const showEditLeverageDialog = localStorage.getItem(AppActionTypes.SET_SHOW_EDIT_LEVERAGE_DIALOG)
const showConfirmEditOrderDialog = localStorage.getItem(AppActionTypes.SET_SHOW_CONFIRM_EDIT_ORDER_DIALOG)

const checkBlacklistArr = (blacklistArr: string[]) => {
  if (blacklistArr.length <= 0) {
    return []
  }
  const filterBlacklist = blacklistArr.filter((blacklistTx) => (
    typeof blacklistTx === 'string' && blacklistTx.length > 0
  ))
  return filterBlacklist
}

let successMsgIds: {
  [key: string]: string[]
} = {}
const successMsgStr = localStorage.getItem(AppActionTypes.SET_SUCCESS_MSG_BLACKLIST)
if (successMsgStr) {
  try {
    const jsonSuccessBlacklist = JSON.parse(successMsgStr)
    if (!(jsonSuccessBlacklist instanceof Object) || jsonSuccessBlacklist instanceof Array) {
      successMsgIds = {}
    }
    if (Object.keys(jsonSuccessBlacklist).length > 0) {
      Object.keys(jsonSuccessBlacklist).forEach((swthKey: string) => {
        const blacklistData = jsonSuccessBlacklist[swthKey] instanceof Array && jsonSuccessBlacklist[swthKey]?.length
          ? checkBlacklistArr(jsonSuccessBlacklist[swthKey])
          : []
        successMsgIds[swthKey] = blacklistData
      })
    }
  } catch (err) {
    successMsgIds = {}
  }
}

let hideSuccessIds: {
  [key: string]: string[]
} = {}
const sessionSuccessIds: string[] = []

const hideSuccessStr = localStorage.getItem(AppActionTypes.SET_HIDE_SUCCESS_MSGS)
if (hideSuccessStr) {
  try {
    const jsonHideSuccess = JSON.parse(hideSuccessStr)
    if (!(jsonHideSuccess instanceof Object) || jsonHideSuccess instanceof Array) {
      hideSuccessIds = {}
    }
    if (Object.keys(jsonHideSuccess).length > 0) {
      Object.keys(jsonHideSuccess).forEach((swthKey: string) => {
        const hideSuccessData = jsonHideSuccess[swthKey] instanceof Array && jsonHideSuccess[swthKey]?.length
          ? checkBlacklistArr(jsonHideSuccess[swthKey])
          : []
        hideSuccessIds[swthKey] = hideSuccessData

        hideSuccessData.forEach((id: string) => {
          if (sessionSuccessIds.indexOf(id) <= -1) {
            sessionSuccessIds.push(id)
          }
        })
      })
    }
  } catch (err) {
    hideSuccessIds = {}
  }
}
sessionStorage.setItem(AppActionTypes.SET_HIDE_SUCCESS_MSGS, JSON.stringify(sessionSuccessIds))

export const DefaultInitialState:
  Record.Factory<AppStateProps> = Record<AppStateProps>({
    subPage: Page.Main,
    debugMode: window?.location?.search.includes('debug=true'),
    walletToggle: false,
    themeType: 'dark',
    carbonSDK: undefined,
    demexConfig: defaultDemexConfig,
    userMnemonic: undefined,
    userProfile: undefined,
    connectedAgent: undefined,
    isRainbowKit: false,
    tokens: [],
    newTokenDenoms: new Set<string>(),
    ledgerSigningType: undefined,
    windowActive: false,
    blockHeight: 0,
    blocksMoving: true,
    showEditLeverageDialog: showEditLeverageDialog !== 'false',
    hideConvertDialog: [],
    openMenu: false,
    openMoreMenu: false,
    openNotificationMenu: false,
    successMsgBlacklist: successMsgIds,
    hideSuccess: hideSuccessIds,
    openNodeMenu: false,
    openDisplayMenu: false,
    openHelpMenu: false,
    nodes: [],
    isShowNodeInfoForm: false,
    customNodes: [],
    selectedNodes: undefined,
    autoSelectNode: true,
    formNode: undefined,
    connectError: undefined,
    bridges: [],
    latency: {},
    gasPricesMap: {},
    cachedWalletLoading: false,
    showMobilePromo: false,
    groupTokensMap: {},
    chainInfoMap: {},
    errorChainInfoMap: '',
    denomTracesHash: '',
    loggingIn: false,
    // confetti animation
    confettiState: false,
    disableConfetti: false,
    legacyAccounts: [],
    isLegacyLogin: false,
    internetConnected: true,
    promoRegistered: false,
    showActivateAccountDialog: false,
    activateAccountTxStatus: TransactionStatus.None,
    subsequentTxStatus: TransactionStatus.None,
    denomTraces: {},
    additionalIbcTokens: undefined,
    thirdPartyAPIStatuses: {
      isCoinGeckoDown: false,
      isHydrogenDown: false,
      isCarbonReferralDown: false,
      isCarbonInsightsDown: false,
      isGithubDown: false,
    },
    referralClient: undefined,
    feeOpen: false,
    showFeedbackBanner: false, // set feedback banner to true and update relevant links for public tests
    pwaInstallPrompt: null,
    openPwaInstallDialog: false,
    showPromoBanner: false,
    showSignlessSettingsDialog: false,
    oracleInfo: [],
    queuePwaUpdateToast: false,
    hiddenBanners: {},
    currentFeeSettingsDropdown: feeDropdownItems[0],
    hiddenTypeformWidgets: [],
    appVersion: '',

    ethersRPCProviders: undefined,
    authDialog: undefined,
    showDemexLoader: true,
    showConfirmEditOrderDialog: showConfirmEditOrderDialog === 'true' || true,
    hiddenMarketBannerIds: [],
  })

const defaultInitialState: AppState = new DefaultInitialState()

export const appReducer = (
  state: AppState = defaultInitialState,
  action: AnyAction,
): AppState => {
  switch (action.type) {
    case AppActionTypes.SET_SUB_PAGE: {
      return state.set('subPage', action.payload)
    }
    case AppActionTypes.SET_WALLET_TOGGLE: {
      return state.set('walletToggle', action.payload)
    }
    case AppActionTypes.SET_THEME_TYPE: {
      return state.set('themeType', action.payload)
    }
    case AppActionTypes.UPDATE_BLOCK_HEIGHT: {
      if (action.payload <= 0) {
        return state
      }
      return state.set('blockHeight', action.payload)
    }
    case AppActionTypes.RESET_BLOCK_HEIGHT: {
      return state.set('blockHeight', 0)
    }
    case AppActionTypes.UPDATE_BLOCKS_MOVING: {
      return state.set('blocksMoving', action.payload)
    }
    case AccountActionTypes.LOGOUT: {
      return state.merge({
        userMnemonic: undefined,
        // carbonSDK: undefined,
        connectedAgent: undefined,
        isRainbowKit: false
      })
    }
    case AppActionTypes.SET_USER_PROFILE: {
      const userProfile = (action.payload as UserProfile | null) ?? undefined
      return state.set('userProfile', userProfile)
    }
    case AppActionTypes.SET_CARBON_SDK: {
      const sdk = action.payload
      return state.merge({ carbonSDK: sdk })
    }
    case AppActionTypes.SET_WINDOW_ACTIVE: {
      return state.set('windowActive', action.payload)
    }
    case AppActionTypes.SET_TOKENS: {
      return state.set('tokens', action.payload)
    }
    case AppActionTypes.SET_NEW_TOKEN_DENOMS: {
      return state.set('newTokenDenoms', action.payload)
    }
    case AppActionTypes.REQUEST_SIGN: {
      return state.set('ledgerSigningType', action.payload)
    }
    case AppActionTypes.FINISH_SIGN: {
      return state.set('ledgerSigningType', undefined)
    }
    case AppActionTypes.SET_SHOW_EDIT_LEVERAGE_DIALOG: {
      localStorage.setItem(AppActionTypes.SET_SHOW_EDIT_LEVERAGE_DIALOG, action.payload)
      return state.set('showEditLeverageDialog', action.payload)
    }
    case AppActionTypes.SET_HIDE_CONVERT_DIALOG: {
      const hideConvertDialogArr = state.hideConvertDialog
      if (!hideConvertDialogArr.includes(action.payload)) {
        hideConvertDialogArr.push(action.payload)
      }
      localStorage.setItem(AppActionTypes.SET_HIDE_CONVERT_DIALOG, JSON.stringify(hideConvertDialogArr))
      return state.set('hideConvertDialog', hideConvertDialogArr)
    }
    // only one dropdonw should be open at a time
    case AppActionTypes.SET_MOBILE_MENU_OPEN: {
      return state.set('openMenu', true)
        .set('openMoreMenu', false)
        .set('openNotificationMenu', false)
        .set('openNodeMenu', false)
        .set('feeOpen', false)
    }
    case AppActionTypes.SET_MOBILE_MENU_CLOSE: {
      return state.set('openMenu', false)
        .set('openNotificationMenu', false)
        .set('openNodeMenu', false)
        .set('feeOpen', false)
        .set('openDisplayMenu', false)
        .set('openHelpMenu', false)
    }
    case AppActionTypes.SET_MOBILE_MORE_MENU_OPEN: {
      return state.set('openMoreMenu', true)
        .set('openNotificationMenu', false)
        .set('openNodeMenu', false)
        .set('feeOpen', false)
    }
    case AppActionTypes.SET_MOBILE_MORE_MENU_CLOSE: {
      return state.set('openMoreMenu', false)
    }
    case AppActionTypes.SET_MOBILE_NOTIFICATION_MENU_OPEN: {
      return state.set('openNotificationMenu', true)
        .set('openMoreMenu', false)
        .set('openNodeMenu', false)
        .set('feeOpen', false)
    }
    case AppActionTypes.SET_MOBILE_NOTIFICATION_MENU_CLOSE: {
      return state.set('openNotificationMenu', false)
    }
    case AppActionTypes.SET_ALL_MENUS_CLOSE: {
      return state.set('openDisplayMenu', false)
        .set('openMenu', false)
        .set('openMoreMenu', false)
        .set('openNodeMenu', false)
        .set('openNotificationMenu', false)
        .set('isShowNodeInfoForm', false)
        .set('formNode', undefined)
        .set('feeOpen', false)
    }
    case AppActionTypes.SET_WALLET_DROPDOWN_OPEN: {
      return state.set('openMoreMenu', false)
        .set('openMenu', false)
        .set('openNotificationMenu', false)
        .set('openNodeMenu', false)
        .set('feeOpen', false)
    }
    case AppActionTypes.SET_NODE_MENU_OPEN: {
      return state.set('openNodeMenu', true)
        .set('openMoreMenu', false)
        .set('openNotificationMenu', false)
        .set('feeOpen', false)
    }
    case AppActionTypes.SET_NODE_MENU_CLOSE: {
      return state.set('openNodeMenu', false)
    }
    // Reserved fees. TODO: Different module?
    case AppActionTypes.SET_FEE_TOGGLE_OPEN: {
      return state.set('feeOpen', true)
        .set('openNodeMenu', false)
        .set('openMoreMenu', false)
        .set('openNotificationMenu', false)
    }
    case AppActionTypes.SET_FEE_TOGGLE_CLOSE: {
      return state.set('feeOpen', false)
    }
    case AppActionTypes.CLEAR_DROPDOWNS: {
      return state.set('openNodeMenu', false)
        .set('openMoreMenu', false)
        .set('openMenu', false)
        .set('openNotificationMenu', false)
        .set('feeOpen', false)
    }
    case AppActionTypes.CLEAR_CONNECTED_WALLET: {
      return state.set('connectedAgent', undefined).set('isRainbowKit', false)
    }
    case AppActionTypes.SET_SUCCESS_MSG_BLACKLIST: {
      const { address, txHash } = action.payload
      let txHashArr = state.successMsgBlacklist?.[address] ?? []
      if (typeof txHash === 'string') {
        txHashArr.push(txHash)
      } else {
        txHashArr = txHashArr.concat(txHash)
      }
      const newBlacklist = {
        ...state.successMsgBlacklist,
        [address]: txHashArr,
      }
      localStorage.setItem(AppActionTypes.SET_SUCCESS_MSG_BLACKLIST, JSON.stringify(newBlacklist))
      return state.set('successMsgBlacklist', newBlacklist)
    }
    case AppActionTypes.SET_HIDE_SUCCESS_MSGS: {
      const { address, txHash } = action.payload
      let txHashArr = state.hideSuccess?.[address] ?? []
      if (typeof txHash === 'string') {
        txHashArr.push(txHash)
      } else {
        txHashArr = txHashArr.concat(txHash)
      }
      const newHideSuccess = {
        ...state.hideSuccess,
        [address]: txHashArr,
      }
      localStorage.setItem(AppActionTypes.SET_HIDE_SUCCESS_MSGS, JSON.stringify(newHideSuccess))
      return state.set('hideSuccess', newHideSuccess)
    }
    case AppActionTypes.SET_DISPLAY_MENU_OPEN: {
      return state.set('openDisplayMenu', true)
    }
    case AppActionTypes.SET_DISPLAY_MENU_CLOSE: {
      return state.set('openDisplayMenu', false)
    }
    case AppActionTypes.SET_HELP_MENU_OPEN: {
      return state.set('openHelpMenu', true)
    }
    case AppActionTypes.SET_HELP_MENU_CLOSE: {
      return state.set('openHelpMenu', false)
    }
    case AppActionTypes.SET_NODES: {
      return state.set('nodes', action.payload)
    }
    case AppActionTypes.SHOW_NODE_INFO_FORM: {
      return state.set('isShowNodeInfoForm', true)
    }
    case AppActionTypes.HIDE_NODE_INFO_FORM: {
      return state.set('isShowNodeInfoForm', false)
    }
    case AppActionTypes.SET_CUSTOM_NODES: {
      return state.set('customNodes', action.payload)
    }
    case AppActionTypes.SET_SELECTED_NODES: {
      return state.set('selectedNodes', action.payload)
    }
    case AppActionTypes.SET_AUTO_SELECT_NODE: {
      return state.set('autoSelectNode', action.payload)
    }
    case AppActionTypes.SET_FORM_NODE: {
      return state.set('formNode', action.payload)
    }
    case AppActionTypes.SET_CONNECT_ERROR: {
      return state.set('connectError', action.payload)
    }
    case AppActionTypes.SET_INTERNET_CONNECTED: {
      return state.set('internetConnected', action.payload)
    }
    case AppActionTypes.SET_ALL_BRIDGES: {
      return state.set('bridges', action.payload)
    }
    case AppActionTypes.SET_RATING_LATENCY: {
      return state.set('latency', action.payload)
    }
    case AppActionTypes.SET_MIN_GAS_PRICES: {
      return state.set('gasPricesMap', action.payload)
    }
    case AppActionTypes.SET_DEMEX_CONFIG: {
      return state.set('demexConfig', action.payload)
    }
    case AppActionTypes.SET_SHOW_MOBILE_PROMO: {
      return state.set('showMobilePromo', action.payload)
    }
    case AppActionTypes.SET_GROUP_TOKENS_MAP: {
      return state.set('groupTokensMap', action.payload)
    }
    case AppActionTypes.SET_CHAIN_INFO_MAP: {
      const hash = ethers.utils.sha256(Buffer.from(JSON.stringify(state.denomTraces), "utf8"))
      return state
        .set('chainInfoMap', action.payload)
        .set('denomTracesHash', hash)
    }
    case AppActionTypes.SET_ERROR_CHAIN_INFO_MAP: {
      return state.set('errorChainInfoMap', action.payload)
    }
    case AppActionTypes.SET_IBC_DENOM_TRACES: {
      return state.set('denomTraces', action.payload)
    }
    case AppActionTypes.SET_ADDITIONAL_IBC_TOKENS: {
      return state.set('additionalIbcTokens', action.payload)
    }
    case AppActionTypes.SET_LOGGING_IN: {
      return state.set('loggingIn', action.payload)
    }
    case AppActionTypes.SET_CONFETTI_STATE: {
      return state.set('confettiState', action.payload)
    }
    case AppActionTypes.SET_DISABLE_CONFETTI: {
      localStorage.setItem(AppActionTypes.SET_DISABLE_CONFETTI, action.payload)
      return state.set('disableConfetti', action.payload)
    }
    case AppActionTypes.SET_PROMO_REGISTERED: {
      return state.set('promoRegistered', action.payload)
    }
    case AppActionTypes.SET_LEGACY_ACCOUNTS: {
      return state.set('legacyAccounts', action.payload)
    }
    case AppActionTypes.SET_LEGACY_LOGIN: {
      return state.set('isLegacyLogin', action.payload)
    }
    case AppActionTypes.SET_SHOW_CREATE_ACCOUNT_DIALOG: {
      return state.set('showActivateAccountDialog', action.payload)
    }
    case AppActionTypes.SET_ACTIVATE_ACCOUNT_TX_STATUS: {
      return state.set('activateAccountTxStatus', action.payload)
    }
    case AppActionTypes.SET_SUBSEQUENT_TX_STATUS: {
      if (state.showActivateAccountDialog) {
        return state.set('subsequentTxStatus', action.payload)
      }
    }
    case AppActionTypes.RESET_ACTIVATE_ACCOUNT_STATUSES: {
      return state.set('activateAccountTxStatus', TransactionStatus.None).set('subsequentTxStatus', TransactionStatus.None)
    }
    case AppActionTypes.SET_THIRD_PARTY_API_STATUSES: {
      return state.set('thirdPartyAPIStatuses', action.payload)
    }
    case AppActionTypes.SET_REFERRAL_CLIENT: {
      return state.set('referralClient', action.payload)
    }
    case AppActionTypes.SET_FEEDBACK_BANNER: {
      return state.set('showFeedbackBanner', action.payload)
    }
    case AppActionTypes.SET_PWA_INSTALL_PROMPT: {
      return state.set('pwaInstallPrompt', action.payload)
    }
    case AppActionTypes.OPEN_PWA_INSTALL_DIALOG: {
      return state.set('openPwaInstallDialog', action.payload)
    }
    case AppActionTypes.SET_PROMO_BANNER: {
      return state.set('showPromoBanner', action.payload)
    }
    case AppActionTypes.SET_ORACLE_INFO: {
      return state.set('oracleInfo', action.payload)
    }
    case AppActionTypes.QUEUE_PWA_UPDATE_TOAST: {
      localStorage.setItem(AppActionTypes.QUEUE_PWA_UPDATE_TOAST, action.payload)
      return state.set('queuePwaUpdateToast', action.payload)
    }
    case AppActionTypes.SET_FEE_SETTINGS_DROPDOWN: {
      const oldItem = state.currentFeeSettingsDropdown
      return state.set('currentFeeSettingsDropdown', oldItem?.key !== action.payload?.key ? action.payload : null)
    }
    case AppActionTypes.ADD_HIDDEN_BANNER: {
      const now = new Date().getTime()
      const hiddenBanners = state.hiddenBanners

      Object.entries(hiddenBanners).forEach(([bannerId, hiddenBanner]) => {
        if (typeof hiddenBanner?.expiry === "number" && hiddenBanner.expiry < now) {
          delete hiddenBanners[bannerId]
        }
      })

      const newHiddenBanners = { ...hiddenBanners, [action.payload]: { expiry: now + HIDDEN_BANNER_DURATION } }
      localStorage.setItem(StorageKey.HiddenBannerKey, JSON.stringify(newHiddenBanners))
      return state.set('hiddenBanners', newHiddenBanners)
    }
    case AppActionTypes.SET_HIDE_TYPEFORM_WIDGET: {
      const hideTypeformWidgetArr = safeParseStoredValue(localStorage.getItem(AppActionTypes.SET_HIDE_TYPEFORM_WIDGET), [])
      if (!hideTypeformWidgetArr.includes(action.payload)) {
        hideTypeformWidgetArr.push(action.payload)
        localStorage.setItem(AppActionTypes.SET_HIDE_TYPEFORM_WIDGET, JSON.stringify(hideTypeformWidgetArr))
      }
      return state.set('hiddenTypeformWidgets', hideTypeformWidgetArr)
    }
    case AppActionTypes.SET_HIDDEN_TYPEFORM_WIDGETS_ARRAY: {
      return state.set('hiddenTypeformWidgets', action.payload)
    }
    case AppActionTypes.SET_APP_VERSION: {
      return state.set('appVersion', action.payload)
    }
    case AppActionTypes.SET_ETHERS_RPC_PROVIDERS: {
      return state.set('ethersRPCProviders', action.payload)
    }
    case AppActionTypes.TOGGLE_AUTH_DIALOG: {
      return state.set('authDialog', action.payload)
    }
    case AppActionTypes.UPDATE_AUTH_DIALOG: {
      const dialog = state.authDialog
      return state.set('authDialog', { ...dialog, ...action.payload })
    }
    case AppActionTypes.SET_SHOW_DEMEX_LOADER: {
      return state.set('showDemexLoader', action.payload)
    }
    case AppActionTypes.SET_SHOW_CONFIRM_EDIT_ORDER_DIALOG: {
      localStorage.setItem(AppActionTypes.SET_SHOW_CONFIRM_EDIT_ORDER_DIALOG, action.payload)
      return state.set('showConfirmEditOrderDialog', action.payload)
    }
    case AppActionTypes.ADD_HIDDEN_MARKET_BANNER_ID: {
      const hiddenMarketBannerIds = state.hiddenMarketBannerIds
      const newHiddenMarketBannerIds = [...hiddenMarketBannerIds, action.payload]
      localStorage.setItem(StorageKey.HiddenMarketBannerIdsKey, JSON.stringify(newHiddenMarketBannerIds))
      return state.set('hiddenMarketBannerIds', newHiddenMarketBannerIds)
    }
    default:
      return state
  }
}
