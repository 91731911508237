import { RefereeDetails, RefLink, TradeListEntry } from 'js/constants/referrals'

import { RootState } from '../rootReducer'

export function getUrlReferralId(state: RootState): string {
  return state.referrals.urlReferralId
}
export function getReferralIdAddr(state: RootState): string {
  return state.referrals.referralIdAddr
}
export function getReferralIdComm(state: RootState): string {
  return state.referrals.referralIdComm
}

export function getReferralIdKb(state: RootState): string {
  return state.referrals.referralIdKb
}

export function getOpenRefPopUp(state: RootState): boolean {
  return state.referrals.openRefPopUp
}

export function getShowRefBanner(state: RootState): boolean {
  return state.referrals.showRefBanner
}

export function getExistingReferrerStatus(state: RootState): boolean | null {
  return state.referrals.existingReferrer
}
export function getReferrerAddr(state: RootState): string {
  return state.referrals.referrerAddress
}

export function getReferrerKb(state: RootState): number {
  return state.referrals.referrerKb
}

export function getReferrerUsername(state: RootState): string {
  return state.referrals.referrerUsername
}

export function getReferrerComm(state: RootState): number {
  return state.referrals.referrerComm
}
export function getRefereeCount(state: RootState): string {
  return state.referrals.refereeCount
}
export function getRefIdRefereeDetails(state: RootState): RefereeDetails[] {
  return state.referrals.refIdRefereeDetails
}
export function getRefereeDetails(state: RootState): RefereeDetails[] {
  return state.referrals.refereeDetails
}

export function getAmtEarned(state: RootState): string {
  return state.referrals.amountEarned
}

export function getTopEarnedAmt(state: RootState): string {
  return state.referrals.topEarned
}

export function getTopEarnedAddr(state: RootState): string {
  return state.referrals.topEarnedAddress
}

export function getRefLinks(state: RootState): RefLink[] {
  return state.referrals.referralLinks
}

export function getAmbassador(state: RootState): boolean {
  return state.referrals.ambassador
}
export function getTradeList(state: RootState): TradeListEntry[] {
  return state.referrals.tradeList
}
export function getShowCommissionOnly(state: RootState): boolean {
  return state.referrals.showCommissionOnly
}
export function getShowKickbackOnly(state: RootState): boolean {
  return state.referrals.showKickbackOnly
}
