import { PageRequest } from 'carbon-js-sdk/lib/codec/cosmos/base/query/v1beta1/pagination'
import Long from 'long'

import { DemexConfig } from 'js/state/modules/app/types'
import { getDefaultNetwork } from 'js/utils/environment'


import { blockchainRankMap, defaultAddIbcTokenConfig, defaultExternalChainChannels } from './TransferOptions'
import { fallbackCrossSellingSourceTokens, fallbackTokenBlacklist } from './assets'
import { fallbackBlacklistedPools } from './liquidityPools'
import { fallbackMarketsBlacklist } from './markets'
import Paths from './paths'

export enum StorageKey {
  CosmosLedgerHdPath = 'cosmosLedgerBIP44Path',
  Mnemonic = '@account/mnemonic',
  ConnectedWallet = '@account/connected-wallet',
  PublicKey = '@account/public-key',
  RainbowKitWalletKey = '@account/rainbowKit',
  HiddenBannerKey = '@app/hiddenBanners',
  ChainInfoMap = '@app/chain-info-map',
  RecentConnectedWeb3WalletId = 'wagmi.recentConnectorId',
  Jwt = '@account/JWT',
  HiddenMarketBannerIdsKey = '@app/hiddenMarketBannerIds',
}

export const StorageVersion = {
  [StorageKey.ChainInfoMap]: 0,
} as const

export interface TabsInfo {
  path: string
  label: string
  value: 'deposit' | 'withdraw' | 'pool_performance' | 'liquidity_distribution' | 'stake' | 'unstake' | 'unbond' | 'stake_fluo' | 'unstake_fluo'
  hideTab?: boolean
}

export const TABS_INFO: {
  [key: string]: TabsInfo
} = {
  deposit: {
    path: Paths.Account.Deposit,
    label: 'Deposit',
    value: 'deposit',
  },
  withdraw: {
    path: Paths.Account.Withdraw,
    label: 'Withdraw',
    value: 'withdraw',
  },
}

export const VAULT_TAB_INFO: {
  [key: string]: TabsInfo
} = {
  deposit: {
    path: Paths.Vaults.Manage,
    label: 'Deposit',
    value: 'deposit',
  },
  withdraw: {
    path: Paths.Vaults.Manage,
    label: 'Withdraw',
    value: 'withdraw',
  },
}

export const VAULT_WITH_INCENTIVES_TAB_INFO: {
  [key: string]: TabsInfo
} = {
  deposit: {
    path: Paths.Vaults.Manage,
    label: 'Deposit',
    value: 'deposit',
  },
  stake: {
    path: Paths.Vaults.Manage,
    label: 'Stake',
    value: 'stake',
    hideTab: true,
  },
  unstake: {
    path: Paths.Vaults.Manage,
    label: 'Unstake',
    value: 'unstake',
    hideTab: true,
  },
  withdraw: {
    path: Paths.Vaults.Manage,
    label: 'Withdraw',
    value: 'withdraw',
  },
}

export const VAULT_PERFORMANCE_TABS_INFO: {
  [key: string]: TabsInfo
} = {
  pool_performance: {
    path: Paths.Vaults.Manage,
    label: 'Pool Performance',
    value: 'pool_performance',
  },
  liquidity_distribution: {
    path: Paths.Vaults.Manage,
    label: 'Liquidity Distribution',
    value: 'liquidity_distribution',
  },
}

const defaultNetwork = getDefaultNetwork()
export const defaultDemexConfig: DemexConfig = {
  network: defaultNetwork,
  prelaunchMarkets: [],
  blacklistedMarkets: fallbackMarketsBlacklist[defaultNetwork],
  blacklistedTokens: fallbackTokenBlacklist[defaultNetwork],
  blacklistedPools: fallbackBlacklistedPools[defaultNetwork],
  transferOptions: {},
  networkFees: blockchainRankMap,
  perpPoolPieChartColors: {},
  marketsOverride: {},
  marketsSpotHistory: {},
  demexPointsConfig: {
    depositsPerSpin: 1000,
    tradingVolumePerSpin: 10000,
  },
  perpPoolPromo: {},
  crossSellingSourceTokens: fallbackCrossSellingSourceTokens[defaultNetwork],
  externalChainChannels: defaultExternalChainChannels[defaultNetwork],
  additionalIbcTokenConfig: defaultAddIbcTokenConfig[defaultNetwork],
  nitronPromoTokens: null,
  nitronPromoLabel: null,
  perpPoolConfig: {
    incentives: {
      distributors: [],
      proxy: '',
    },
    banners: [],
  },
  wSWTHContract: '',
  marketBanners: [],
}

export const defaultPagination = PageRequest.fromPartial({
  limit: new Long(10000),
  offset: Long.UZERO,
  key: new Uint8Array(),
  countTotal: false,
  reverse: false,
})
