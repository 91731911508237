import { RecordOf } from 'immutable'

export interface LlamaContent {
  title: string
  subTitle: string
  createdAt: string
  thumbnail?: string
  url: string
}

export interface LlamaWidgetStateProps {
 tweets: LlamaContent[]
 news: LlamaContent[]
 filteredTweets: LlamaContent[]
 filteredNews: LlamaContent[]
 latestContent?: LlamaContent
}
export type LlamaWidgetState = RecordOf<LlamaWidgetStateProps>

export const LlamaWidgetActionTypes = {
  SET_TWEETS: '@llamaWidget/SET_TWEETS',
  SET_NEWS: '@llamaWidget/SET_NEWS',
  SET_FILTERED_TWEETS: '@llamaWidget/SET_FILTERED_TWEETS',
  SET_FILTERED_NEWS: '@llamaWidget/SET_FILTERED_NEWS',
  SET_LATEST_CONTENT: '@llamaWidget/SET_LATEST_CONTENT',
  QUERY_LLAMA_TWEET: '@llamaWidget/QUERY_LLAMA_TWEET',
  QUERY_LLAMA_NEW: '@llamaWidget/QUERY_LLAMA_NEW',
}
