import { RecordOf } from 'immutable'

import { RefereeDetails, RefLink, TradeListEntry } from 'js/constants/referrals'

export interface ReferralStateProps {
  urlReferralId: string,
  referralIdAddr: string,
  referralIdComm: string,
  referralIdKb: string,
  refIdRefereeDetails: RefereeDetails[],
  openRefPopUp: boolean,
  showRefBanner: boolean,
  existingReferrer: boolean | null,
  referrerAddress: string,
  referrerComm: number,
  referrerKb: number,
  referrerUsername: string,
  refereeCount: string,
  refereeDetails: RefereeDetails[],
  referralLinks: RefLink[],
  amountEarned: string,
  ambassador: boolean,
  topEarned: string,
  topEarnedAddress: string,
  tradeList: TradeListEntry[],
  showCommissionOnly: boolean,
  showKickbackOnly: boolean,
}
export type ReferralState = RecordOf<ReferralStateProps>

export const ReferralActionTypes = {
  SET_AMBASSADOR: '@referrals/SET_AMBASSADOR',
  SET_URL_REFERRAL_ID: '@referrals/SET_URL_REFERRAL_ID',
  SET_REFERRAL_ID_ADDR: '@referrals/SET_REFERRAL_ID_ADDR',
  SET_REFERRAL_ID_COMM: '@referrals/SET_REFERRAL_ID_COMM',
  SET_REFERRAL_ID_KB: '@referrals/SET_REFERRAL_ID_KB',
  SET_REF_ID_REFEREE_DETAILS: '@referrals/SET_REF_ID_REFEREE_DETAILS',
  SET_OPEN_REF_POPUP: '@referrals/SET_OPEN_REF_POPUP',
  SET_SHOW_REF_BANNER: '@referrals/SET_SHOW_REF_BANNER',
  SET_EXISTING_REFERRER: '@referrals/SET_EXISTING_REFERRER',
  SET_REFERRER_ADDR: '@referrals/SET_REFERRER_ADDR',
  SET_REFERRER_COMM: '@referrals/SET_REFERRER_COMM',
  SET_REFERRER_KB: '@referrals/SET_REFERRER_KB',
  SET_REFERRER_USERNAME: '@referrals/SET_REFERRER_USERNAME',
  SET_REFEREE_COUNT: '@referrals/SET_REFEREE_COUNT',
  SET_REFEREE_DETAILS: '@referrals/SET_REFEREE_DETAILS',
  SET_REF_LINKS: '@referrals/SET_REF_LINKS',
  SET_AMT_EARNED: '@referrals/SET_AMT_EARNED',
  SET_TOP_EARNED: '@referrals/SET_TOP_EARNED',
  SET_TOP_EARNED_ADDR: '@referrals/SET_TOP_EARNED_ADDR',
  SET_TRADE_LIST: '@referrals/SET_TRADE_LIST',
  SET_SHOW_COMMISSION_ONLY: '@referrals/SET_SHOW_COMMISSION_ONLY',
  SET_SHOW_KICKBACK_ONLY: '@referrals/SET_SHOW_KICKBACK_ONLY',
  QUERY_REF_LINKS: '@referrals/QUERY_REF_LINKS',
  QUERY_FOR_REFERRER: '@referrals/QUERY_FOR_REFERRER',
  QUERY_FOR_REFEREES: '@referrals/QUERY_FOR_REFEREES',
  QUERY_FOR_REF_ID_REFEREE_DETAILS: '@referrals/QUERY_FOR_REF_ID_REFEREE_DETAILS',
  QUERY_FOR_REFEREE_DETAILS: '@referrals/QUERY_FOR_REFEREE_DETAILS',
  QUERY_FOR_TRADE_LIST: '@referrals/QUERY_FOR_TRADE_LIST',
  RESET_REFERRALS: '@referrals/RESET_REFERRALS',
}
